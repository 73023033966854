import { TranslateModule } from '@ngx-translate/core';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PopupComponent } from './popup.component';

@NgModule({
  entryComponents: [
    PopupComponent
  ],
  declarations: [
    PopupComponent
  ],
  imports: [
    NgbModule,
    TranslateModule.forChild()
  ],
  exports: [
    PopupComponent
  ]
})
export class PopupModule {
}
