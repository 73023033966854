<ng-template #popup let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content select="[popup-body]"></ng-content>
  </div>
  <div class="modal-footer">
    <ng-content select="[popup-footer]"></ng-content>
  </div>
</ng-template>